import { faCircleCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as axios from "axios";
import clsx from "clsx";
import { get } from "lodash";
import moment from "moment";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFlexTaskOrderLinesList,
  selectProjectFlexTasksList,
  selectRefreshFlexTaskId,
  selectSelectedTaskOrderlines,
  updateFlexTaskOrderLineStatus,
  updateProjectFlexTaskStatusList,
  updateRefreshFlexTaskId,
  updateSelectedTaskOrderlines,
  upsertFlexTaskOrderLines,
} from "../../../features/projectManagement/projectManagementSlice";
import { ProjectManagementPmrWrapper } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import {
  generateGlobalSearchFilters,
  selectShowHideColumnProjectsTableOrderLine,
  selectTimeEntryUpdateTaskOrderlines,
  updateShowHideColumnByTable,
  updateTimeEntryUpdateTaskOrderlines,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import usePmrShiftSelect, {
  ComponentWithIndices,
} from "../../../hooks/usePmrShiftSelect";
import useProjectTimestamp from "../../../hooks/useProjectTimestamp";
import useToastr from "../../../hooks/useToastr";
import ProjectService from "../../../services/ProjectService";
import { formatDateWithTimezone } from "../../../shared/date-helper/DateHelper";
import { TimestampProjectLineMilestonesModel } from "../../../shared/models/service-models/TimestampProjectLineMilestones.model";
import {
  GMATCTaskName,
  isGmaTcTask,
} from "../../../utils/constants/flex-task-name.constants";
import {
  coHandlerErrorHeader,
  coHandlerErrorMessage,
} from "../../../utils/constants/projects.constants";
import { formatNumber } from "../../../utils/helpers/number.helpers";
import TimestampElements from "../../project-management/shared/components/timestamp-elements/TimestampElements";
import { GridColumnModel } from "../data/project-grid-columns";
import {
  projectOrderLineColumnCollaborator,
  projectOrderLineColumnMyHours,
  projectOrderLineColumnOrderLine,
  projectOrderLineColumnStatus,
  projectOrderLineColumnTaskName,
  projectOrderLineColumnTotalHours,
} from "../data/project-order-line-grid-columns";
import PmrDmIcons from "../shared/components/pmr-dm-icons/PmrDmIcons";
import ProjectCollaboratorAssignmentDialog from "../shared/components/project-collaborator-assignment-dialog/ProjectCollaboratorAssignmentDialog";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import ProjectOrderLineKebabAction from "../shared/components/project-orderline-kebab-actions/ProjectOrderLineKebabActions";
import ProjectTaskOrderlineStatusTemplate from "../shared/components/project-task-orderline-status-template/ProjectTaskOrderlineStatusTemplate";
import { CoHandlerErrorStatus } from "../shared/enums/co-handler-error-status";
import { ECDAdjustmentsErrorType } from "../shared/enums/ecd-adjustments-error-type";
import ProjectTaskStatus from "../shared/enums/project-task-status";
import SubmitOption from "../shared/enums/submit-option";
import { getProjectLineIsCompletedForTask } from "../shared/interfaces/pmr-e2e-project-details-orderline-model";
import PmrE2EOnHoldReason from "../shared/interfaces/pmr-e2e-project-onhold-reason";
import PmrE2EProjectOrderlineModel from "../shared/interfaces/pmr-e2e-project-orderline-model";
import PmrE2EProjectTaskHandler from "../shared/interfaces/pmr-e2e-project-task-handlers";
import classes from "./ProjectOrderLineList.module.scss";
import TaskCommentButton from "./task-comment-button/TaskCommentButton";
import { Tooltip } from "primereact/tooltip";
import { ResultModel } from "../../../models/result.model";

interface ProjectOrderLineProps extends ComponentWithIndices {
  projectId: string;
  workBreakdownStructure: number;
  e2EProjectLineFlexTaskIds: string[];
  getTaskList: () => void;
  hasGMATCTask: boolean | null;
  openAssignCoHandlerDialog?(
    rowData: any,
    isMilestoneLevel: boolean,
    showTimeStampError: boolean,
    index?: number
  ): void;
}

interface PmrUpdateProjectLineStatusModel {
  e2EProjectId: string;
  e2EProjectLineFlexTaskId: string;
  status: ProjectTaskStatus;
}

const ProjectOrderLineList = (props: ProjectOrderLineProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const flexTaskOrderLinesList = useSelector(selectFlexTaskOrderLinesList);
  const projectOrderLines =
    flexTaskOrderLinesList.find(
      (list) =>
        list.projectId === props.projectId &&
        list.workBreakdownStructure === props.workBreakdownStructure
    )?.orderLines || [];
  const selectProjectsColumns = useSelector(
    selectShowHideColumnProjectsTableOrderLine
  );

  const [columns, setColumns] = useState<GridColumnModel[]>(
    selectProjectsColumns!.fields
  );

  const { showError, showSuccess, showInfo } = useToastr();
  const datatableRef = useRef<any>(null);
  const actionsPopOverRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const selectedTaskOrderlines = useSelector(selectSelectedTaskOrderlines);
  const projectTimestampHook = useProjectTimestamp();
  const generatedGlobalFilters = useSelector<
    ProjectManagementPmrWrapper,
    { label: string; value?: any }[]
  >((state) => generateGlobalSearchFilters(state, props.projectId));
  const shiftSelect = usePmrShiftSelect(props.depthIndices, projectOrderLines);
  const [selectedE2EProjectLineFlexTask, setSelectedE2EProjectLineFlexTask] =
    useState<{
      e2EProjectLineFlexTaskId: string;
      collaborators: PmrE2EProjectTaskHandler[];
      isGmaTcTask: boolean;
    }>();

  const [selectedE2EProjectLineId, setSelectedE2EProjectLineId] = useState("");
  const [selectedProjectNumber, setSelectedProjectNumber] = useState<string>();
  const [dbClickRowData, setDbClickRowData] = useState(null);
  const updatedTaskOrderlinesFromTimeEntry = useSelector(
    selectTimeEntryUpdateTaskOrderlines
  );

  const projectFlexTasksList = useSelector(selectProjectFlexTasksList);
  const projectTasks =
    projectFlexTasksList.find((list) => list.e2eProjectId === props.projectId)
      ?.tasks || [];
  const [selectedIsCoHandlerMilestone, setSelectedIsCoHandlerMilestone] =
    useState(false);

  const refreshFlexTaskId = useSelector(selectRefreshFlexTaskId);

  useEffect(() => {
    setColumns(selectProjectsColumns!.fields);
  }, [selectProjectsColumns]);

  useEffect(() => {
    if (datatableRef.current) {
      datatableRef.current.restoreColumnWidths();
      datatableRef.current.reset();
    }
  }, [columns]);

  useEffect(() => {
    if (shiftSelect.rangeItems.length > 0) {
      dispatch(
        updateSelectedTaskOrderlines(
          shiftSelect.rangeItems.map((item) =>
            getSelectedTaskOrderlineState(item)
          )
        )
      );
    }
  }, [shiftSelect.rangeItems]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (refreshFlexTaskId) {
      if (props.e2EProjectLineFlexTaskIds.some(x => x === refreshFlexTaskId)) {
        getList();
        dispatch(updateRefreshFlexTaskId(""));
      }
    }
  }, [refreshFlexTaskId]);

  useEffect(() => {
    if (updatedTaskOrderlinesFromTimeEntry) {
      checkIfHasTimeEntryUpdate();
    }
  }, [updatedTaskOrderlinesFromTimeEntry]);

  const checkIfHasTimeEntryUpdate = () => {
    const updatedE2EProjectLineFlexTaskIds =
      updatedTaskOrderlinesFromTimeEntry.map(
        (task) => task.e2EProjectLineFlexTaskId
      );

    const e2EProjectLineFlexTaskIds = projectOrderLines.map(
      (taskOrderline) => taskOrderline.e2EProjectLineFlexTaskId
    );
    const hasAnUpdate = e2EProjectLineFlexTaskIds.some(
      (id) => updatedE2EProjectLineFlexTaskIds.indexOf(id) > -1
    );
    if (hasAnUpdate) {
      getList();
      dispatch(updateTimeEntryUpdateTaskOrderlines([]));
    }
  };

  const getListCancelSource = useRef<any>(null);
  const getList = async () => {
    setLoading(true);
    const request: any = {
      e2EProjectLineFlexTaskIds: props.e2EProjectLineFlexTaskIds,
    };

    if (getListCancelSource.current) {
      getListCancelSource.current.cancel();
    }

    getListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const response = await ProjectService.getProjectOrderline(
        request,
        getListCancelSource.current
      );

      if (response.isSuccess) {
        let data = response?.data ?? [];
        let records = data.map((orderLine: PmrE2EProjectOrderlineModel) => {
          orderLine.startDate = formatDateWithTimezone(orderLine.startDate);
          orderLine.dueDate = formatDateWithTimezone(orderLine.endDate);
          orderLine.completionDate = formatDateWithTimezone(
            orderLine.completionDate
          );
          orderLine.isChecked = false;
          orderLine.isExpand = false;
          orderLine.orderLineNumber = setOrderLineNumber(orderLine);
          orderLine.status = orderLine.orderLineStatus;
          orderLine.projectNumber = orderLine.flexProjectNumber;
          orderLine.collaborator = orderLine.e2EProjectLineCollaborators;
          orderLine.myHours = formatNumber(orderLine.myHours);
          orderLine.totalHours = formatNumber(orderLine.totalHours);
          orderLine.openAssignCoHandlerDialog = props.openAssignCoHandlerDialog;
          return orderLine;
        });

        dispatch(
          upsertFlexTaskOrderLines({
            projectId: props.projectId,
            workBreakdownStructure: props.workBreakdownStructure,
            orderLines: records,
          })
        );
      }
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Orderlines request cancelled");
        return;
      }
    }
    setLoading(false);
  };

  const setOrderLineNumber = (orderLine: PmrE2EProjectOrderlineModel) => {
    const description =
      orderLine.serviceCatalogDescription || orderLine.serviceProgram;
    return (
      orderLine?.orderLineDescription ??
      `${orderLine.lineNumber} - ${description}`
    );
  };

  const handleOrderlineStatusChange = (
    onHoldReason?: PmrE2EOnHoldReason,
    result?: ResultModel,
    taskName?: string,
    orderLineNumber?: string,
    status?: ProjectTaskStatus
  ) => {
    const resultData = result?.data as Array<PmrUpdateProjectLineStatusModel>;
    const message = result?.message;

    resultData?.forEach((item: PmrUpdateProjectLineStatusModel) => {
      dispatch(
        updateFlexTaskOrderLineStatus({
          projectId: item.e2EProjectId,
          e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
          newStatus: item.status,
          onHoldReason,
        })
      );

      dispatch(
        updateProjectFlexTaskStatusList({
          projectId: item.e2EProjectId,
          e2EProjectLineFlexTaskId: item.e2EProjectLineFlexTaskId,
          newStatus: item.status,
        })
      );
    });

    if (result?.isSuccess) {
      if (onHoldReason)
        showInfo(
          `FLEX Task Status updated to 'On Hold`,
          `<b>${taskName}</b> for order line <b>${orderLineNumber}</b> has been placed On Hold.
         Reason: <b>${onHoldReason.taskStatusReason}</b>. Related ongoing milestones have also been updated.
        `
        );
      else if (status === ProjectTaskStatus.Cancelled) {
        showInfo(
          `Order Line FLEX Task Cancelled`,
          `FLEX Task in Order Line <b>${orderLineNumber}</b> has been successfully cancelled.`
        );
      } else showSuccess("Success", message);
    } else showError("Update Incomplete", message);
  };

  const getOverdueClass = (dueDate?: string, status?: ProjectTaskStatus) => {
    if (!dueDate) return false;
    if (!status) return false;
    const formattedDueDate = new Date(dueDate);
    const currentDateOnly = moment().startOf("day").toDate();

    const overDueStatuses = new Set([
      ProjectTaskStatus.NotScheduled,
      ProjectTaskStatus.NotStarted,
      ProjectTaskStatus.AwaitingAssignment,
      ProjectTaskStatus.InProgress,
      ProjectTaskStatus.OnHold,
    ]);

    return currentDateOnly > formattedDueDate && overDueStatuses.has(status);
  };

  const tableColumnHeader = (col: any) => (
    <div
      title={
        col.id === projectOrderLineColumnStatus
          ? "Please timestamp your order line tasks to mark them as 'Completed'."
          : col.value
      }
      style={
        col.id === projectOrderLineColumnStatus ? { cursor: "pointer" } : {}
      }
    >
      <span>{col.value}</span>
    </div>
  );

  const tableColumnBody = (rowData: PmrE2EProjectOrderlineModel, col: any) => {
    return (
      <div
        title={get(rowData, col.field)}
        className={clsx(
          `text-container-ellipsis`,
          getOverdueClass(rowData.dueDate || "", rowData.status) &&
          classes["order-line-overdue"],

          //   getProjectLineIsCompleted(
          //   rowData.e2EProjectLineStatus,
          //   isGmaTcTask(rowData.flexTaskName),
          //   rowData.orderLineStatus == ProjectTaskStatus.Completed
          // ) && classes["project-line-is-completed"]

          getProjectLineIsCompletedForTask(
            rowData.e2EProjectLineStatus,
            rowData
          ) && classes["project-line-is-completed"]
        )}
      >
        {get(rowData, col.field)}
      </div>
    );
  };

  const onOrderLineKebabClicked = (rowData: any, e: any) => {
    setSelectedRow(rowData);
    if (actionsPopOverRef.current?.openPopUp)
      actionsPopOverRef?.current?.openPopUp(e);
  };
  const isTaskOrderlineSelected = (orderLine: PmrE2EProjectOrderlineModel) => {
    return selectedTaskOrderlines.some(
      (line) =>
        line.e2EProjectLineFlexTaskId ===
        orderLine.e2EProjectLineFlexTaskId?.toString()
    );
  };

  const getSelectedTaskOrderlineState = (
    rowData: PmrE2EProjectOrderlineModel
  ) => {
    return {
      e2EProjectLineFlexTaskId: rowData.e2EProjectLineFlexTaskId,
      status: rowData.status,
      isRevenueRecognition: rowData.isRevenueRecognition,
      isAdHoc: rowData.isAdHoc,
    };
  };

  const handleOrderLineNumberDoubleClick = async (rowData: any) => {
    setDbClickRowData(rowData);
    const taskIds = [rowData.e2EProjectLineFlexTaskId];
    const milestoneRequest = {
      e2EProjectLineFlexTaskIds: props.e2EProjectLineFlexTaskIds,
      workBreakdownStructure: props.workBreakdownStructure,
    };
    const milestoneResult = await ProjectService.getProjectMilestoneTask(
      milestoneRequest
    );
    const milestoneIds = milestoneResult.data.e2EProjectLineMilestones.map(
      (milestone: any) => milestone.id
    );

    const task = {
      status: rowData.status,
      isTimestamping: rowData.isTimestamping,
    };

    if (!projectTimestampHook.isTaskTimestampable(task)) return;

    const result = await projectTimestampHook.checkProjectLinesECDAdjustments({
      taskIds,
      milestoneIds,
      includeMilestonePredecessorsForTimestamping: false,
      isMilestoneLoaded: false,
    });

    const payload: TimestampProjectLineMilestonesModel = {
      milestoneIds: milestoneIds,
      taskIds: taskIds,
      adjustECDProjectLineIds: [],
      isFromECDAdjustmentPrompt: false,
      includeMilestonePredecessorsForTimestamping: false,
      isTimestampedThroughTasks: true,
    };

    if (result?.isSuccess && result?.data?.ecdAdjustments.length === 0) {
      await projectTimestampHook.timestampMilestones(payload);
    } else {
      if (result?.data.errorType == ECDAdjustmentsErrorType.CoHandlerError) {
        if (result?.data.coHandlerErrorModel.length == 1) {
          const coHandlerError = result.data.coHandlerErrorModel[0];

          if (
            coHandlerError.coHandlerErrorStatus ==
            CoHandlerErrorStatus.Unassigned
          ) {
            if (props.openAssignCoHandlerDialog)
              props.openAssignCoHandlerDialog(rowData, false, true);
          } else {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                coHandlerError.orderLineNumber
              )
            );
          }
        } else {
          result.data.coHandlerErrorModel.forEach((item) => {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                item.orderLineNumber
              )
            );
          });
        }
      }
    }
  };

  const nameColumnBodyTemplate = (
    rowData: PmrE2EProjectOrderlineModel,
    col: any
  ) => {
    const isCheckboxDisabled = [
      ProjectTaskStatus.Cancelled,
      ProjectTaskStatus.Completed,
    ].includes(rowData.status);
    return (
      <div className={classes["orderline-cell-container"]}>
        <div
          className={clsx(
            classes["left"],
            getOverdueClass(rowData.dueDate || "", rowData.status) &&
            classes["order-line-overdue"],
            getProjectLineIsCompletedForTask(
              rowData.e2EProjectLineStatus,
              rowData
            ) && classes["project-line-is-completed"]
          )}
          title={
            getOverdueClass(rowData.dueDate || "", rowData.status) &&
              !getProjectLineIsCompletedForTask(
                rowData.e2EProjectLineStatus,
                rowData
              )
              ? "This Task/Milestone is overdue. Please review and take necessary action"
              : get(rowData, col.field)
          }
        >
          {!getProjectLineIsCompletedForTask(
            rowData.e2EProjectLineStatus,
            rowData
          ) && (
              <Checkbox
                disabled={isCheckboxDisabled}
                checked={!isCheckboxDisabled && isTaskOrderlineSelected(rowData)}
                onChange={(event: CheckboxChangeParams) => {
                  shiftSelect.register(
                    [...props.depthIndices, col.rowIndex],
                    event
                  );
                  dispatch(
                    updateSelectedTaskOrderlines({
                      isChecked: event.checked,
                      taskOrderline: getSelectedTaskOrderlineState(rowData),
                    })
                  );
                }}
                className={`${classes["task-checkbox"]}`}
              />
            )}
          {getProjectLineIsCompletedForTask(
            rowData.e2EProjectLineStatus,
            rowData
          ) && (
              <span className={classes["check-icon"]}>
                <FontAwesomeIcon icon={faCircleCheck} />
              </span>
            )}
          <div
            onDoubleClick={() => handleOrderLineNumberDoubleClick(rowData)}
            className={classes["order-line-number-text"]}
          >
            {get(rowData, col.field)}
          </div>
        </div>
        <div className={`${classes["right"]}`}>
          <PmrDmIcons rowData={rowData} />
          <TaskCommentButton rowData={rowData} />

          <Button
            type="button"
            icon="pi pi-ellipsis-v"
            onClick={(e) => onOrderLineKebabClicked(rowData, e)}
            className={`p-button-text p-button-secondary`}
          />
        </div>
      </div>
    );
  };

  const getRowTimestampingClassName = (rowData: any) => {
    return clsx(rowData.isTimestamping && classes["is-timestamping"]);
  };

  const statusColumnBodyTemplate = (rowData: any) => {
    let toolTipMessage = "";
    const adhocTooltipMessage =
      "Status updates automatically based on assignment and start date criteria: Not Scheduled (no Start Date, with or without Task Handler), Awaiting Assignment (Start Date set, no Task Handler), Not Started (future Start Date), In Progress (Start Date today or past date, and has Task Handler).";

    switch (rowData[projectOrderLineColumnStatus]) {
      case ProjectTaskStatus.OnHold:
        toolTipMessage = rowData.flexProjectTaskHoldReason?.taskStatusReason;
        break;
      case ProjectTaskStatus.Cancelled:
        if (rowData.isAdHoc) toolTipMessage = adhocTooltipMessage;

        toolTipMessage =
          "Editing start and due dates, logging work hours and timestamping are disabled for cancelled tasks / milestones.";
        break;

      default:
        if (rowData.isAdHoc) toolTipMessage = adhocTooltipMessage;

        toolTipMessage = "Current status of this order line's Task/Milestone.";
    }

    return (
      <ProjectTaskOrderlineStatusTemplate
        containerStyle={{ cursor: "pointer" }}
        isLoading={rowData.isTimestamping}
        tooltip={toolTipMessage}
        status={rowData[projectOrderLineColumnStatus]}
        startDate={rowData["flexStartDate"]}
        isAdHoc={rowData["isAdHoc"]}
        orderlineNumber={rowData[projectOrderLineColumnOrderLine]}
        handleOrderlineStatusChange={handleOrderlineStatusChange}
        E2EProjectLineId={rowData.id}
        E2EProjectLineFlexTaskId={rowData.e2EProjectLineFlexTaskId}
        totalHours={Number(rowData.totalHours)}
        taskName={rowData[projectOrderLineColumnTaskName]}
      />
    );
  };

  const [
    showCollaboratorAssignmentDialog,
    setShowCollaboratorAssignmentDialog,
  ] = useState<boolean>(false);

  const onCollaboratorClick = (
    e2EProjectLineFlexTaskId: string,
    collaborators: PmrE2EProjectTaskHandler[],
    isGmaTcTask: boolean,
    e2eProjectLineId: string,
    projectNumber: string
  ) => {
    const currentOrderLine: any = projectOrderLines.find(
      (x) => x.e2EProjectLineFlexTaskId == e2EProjectLineFlexTaskId
    );

    if (
      [ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
        currentOrderLine.status
      )
    )
      return;

    if (
      currentOrderLine.submitOption == SubmitOption.AuthorityReviewFlex &&
      currentOrderLine.flexTaskName == "Certification Office Review" &&
      !currentOrderLine.isAssignCoHandlerMailboxNotRequired
    ) {
      setSelectedIsCoHandlerMilestone(false);
      return;
    }

    if (
      currentOrderLine.submitOption == SubmitOption.AuthorityReviewFlex &&
      currentOrderLine.flexTaskName == "Certification Office Review" &&
      currentOrderLine.isAssignCoHandlerMailboxNotRequired
    ) {
      setSelectedIsCoHandlerMilestone(true);
    }

    setSelectedE2EProjectLineFlexTask((current) => ({
      ...current,
      e2EProjectLineFlexTaskId: e2EProjectLineFlexTaskId,
      collaborators: collaborators,
      isGmaTcTask: isGmaTcTask,
    }));
    setSelectedE2EProjectLineId(e2eProjectLineId);
    setSelectedProjectNumber(projectNumber);
    setShowCollaboratorAssignmentDialog(true);
  };

  const onCollaboratorClose = () => {
    setShowCollaboratorAssignmentDialog(false);
  };

  const collaboratorColumnBodyTemplate = (rowData: any) => {
    const gmaTcTask = GMATCTaskName;

    const isGmaTcTask = rowData.flexTaskName
      .toLowerCase()
      .includes(gmaTcTask.toLowerCase());

    return (
      <ProjectCollaboratorAvatar
        onDoubleClick={() => {
          onCollaboratorClick(
            rowData.e2EProjectLineFlexTaskId,
            rowData[projectOrderLineColumnCollaborator],
            isGmaTcTask,
            rowData.id,
            rowData.projectNumber
          );
        }}
        collaborator={rowData[projectOrderLineColumnCollaborator]}
        isAdHoc={rowData.isAdHoc}
      ></ProjectCollaboratorAvatar>
    );
  };

  const hoursColumnBodyTemplate = (
    rowData: PmrE2EProjectOrderlineModel,
    col: any
  ) => {
    return (
      <div
        title={get(rowData, col.field)}
        className={clsx(
          `text-container-ellipsis`,
          `tooltip-target-${col.field}-${rowData.id}`,
          getOverdueClass(rowData.dueDate || "", rowData.status) &&
          classes["order-line-overdue"],
          getProjectLineIsCompletedForTask(
            rowData.e2EProjectLineStatus,
            rowData
          ) && classes["project-line-is-completed"]
        )}
      >
        <span
          style={{
            opacity:
              getIsFlexTaskTimeEntrySyncLoading(
                rowData.e2EProjectLineFlexTaskId
              ) || rowData.isAdHoc
                ? ".35"
                : "1",
          }}
        >
          {get(rowData, col.field)}
        </span>
        {getIsFlexTaskTimeEntrySyncLoading(rowData.e2EProjectLineFlexTaskId) &&
          loadingIndicator}
        <Tooltip
          target={`.tooltip-target-${col.field}-${rowData.id}`}
          className="custom-tooltip"
          my="left top"
          at="left bottom"
        >
          {getIsFlexTaskTimeEntrySyncLoading(rowData.e2EProjectLineFlexTaskId)
            ? `Updating Time Entry...`
            : ``}
        </Tooltip>
      </div>
    );
  };

  const getIsFlexTaskTimeEntrySyncLoading = (
    e2EProjectLineFlexTaskId: string
  ): boolean => {
    let isTimeEntrySyncing: boolean = false;
    if (projectTasks) {
      const task = projectTasks?.find(
        (task) => task.workBreakdownStructure === props.workBreakdownStructure
      );
      if (task) {
        for (let id of props.e2EProjectLineFlexTaskIds) {
          const te = task.timeEntrySnycFlexTaskList.find(
            (x) => x.e2EProjectLineFlexTaskId == id
          );
          if (te && te.e2EProjectLineFlexTaskId == e2EProjectLineFlexTaskId) {
            isTimeEntrySyncing = te.isTimeEntrySyncing;
          } else {
            continue;
          }
        }
      }
    }
    return isTimeEntrySyncing;
  };

  const processColumnBodyTemplates = (col: any) => {
    if (col.id === projectOrderLineColumnOrderLine)
      return nameColumnBodyTemplate.bind(col);
    if (col.id === projectOrderLineColumnStatus)
      return statusColumnBodyTemplate.bind(col);
    if (col.id === projectOrderLineColumnCollaborator)
      return collaboratorColumnBodyTemplate.bind(col);
    if (
      col.id === projectOrderLineColumnMyHours ||
      col.id === projectOrderLineColumnTotalHours
    )
      return hoursColumnBodyTemplate.bind(col);

    return tableColumnBody.bind(col);
  };

  const dynamicColumns = columns.map((col: GridColumnModel) => {
    const isCollaborator = col.id === projectOrderLineColumnCollaborator;
    return (
      <Column
        field={col.id}
        hidden={!col.isShown}
        header={tableColumnHeader(col)}
        style={
          col.isFlexWidth
            ? {
              flexGrow: 1,
              flexBasis: col.width,
              width: col.width,
              minWidth: 80,
              padding: "0.3rem",
            }
            : {
              width: col.width,
              minWidth: col.isFrozen
                ? col.width
                : isCollaborator
                  ? col.widthNum
                  : 50,
              padding: "0.3rem",
            }
        }
        body={processColumnBodyTemplates(col)}
        sortable={col.orderBy != ""}
        reorderable={!col.isColumnNotReordable && !col.isFrozen}
      />
    );
  });

  const rowDetailsTemplate = () => {
    return <>Content</>;
  };

  const onColReorder = (e: any) => {
    if (e.dropIndex < 2) {
      datatableRef.current.reset();
    } else {
      let prevNumber = 0;

      let pwqColumns = columns.map((col) => {
        const orderNo = e.columns.findIndex(
          (fi: any) => fi.props.field === col.id
        );

        return {
          ...col,
          orderNo,
        };
      });

      pwqColumns?.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      pwqColumns = pwqColumns
        .map((col) => {
          if (col.orderNoShowHide! > 0) prevNumber++;

          const orderNoShowHide = col.orderNoShowHide! > 0 ? prevNumber : 0;

          return {
            ...col,
            orderNoShowHide,
          };
        })
        .sort((col) => col.orderNoShowHide);

      dispatch(
        updateShowHideColumnByTable({
          key: selectProjectsColumns?.key,
          newColumns: pwqColumns,
          subHeader: "Order Line",
        })
      );
    }
  };

  const onColResize = (e: any) => {
    let newColumns = columns.map((col) => {
      if (e.column.props.field == col.id) {
        return {
          ...col,
          width: e.element.offsetWidth,
        };
      } else {
        return col;
      }
    });
    dispatch(
      updateShowHideColumnByTable({
        key: selectProjectsColumns?.key,
        newColumns: newColumns,
        subHeader: "Order Line",
      })
    );
  };

  const handleECDAdjustmentsConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    projectTimestampHook.hideECDAdjustmentsDialog();
    await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      taskIds: projectTimestampHook.currentTaskIds,
      adjustECDProjectLineIds,
      isFromECDAdjustmentPrompt: true,
      includeMilestonePredecessorsForTimestamping: false,
      isTimestampedThroughTasks: true,
    });
    getList();
  };

  const loadingIndicator = (
    <FontAwesomeIcon
      icon={faSpinner}
      spin={true}
      style={{ marginLeft: "7px", color: "#333333" }}
    />
  );

  return (
    <div className={`${classes["table-container"]}`}>
      <div className={`${classes["project-order-line-list-grid-container"]}`}>
        <DataTable
          emptyMessage="--- No results found. Please try different search criteria or check your spelling. ---"
          ref={datatableRef}
          dataKey="id"
          value={projectOrderLines}
          loading={loading}
          selection={selectedRecords}
          onSelectionChange={(e: any) => setSelectedRecords(e.value)}
          expandedRows={expandedRows}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowDetailsTemplate}
          scrollable
          resizableColumns
          size="small"
          scrollDirection="both"
          columnResizeMode="fit"
          reorderableColumns
          onColReorder={onColReorder}
          onColumnResizeEnd={onColResize}
          removableSort
          rowClassName={(rowData) => getRowTimestampingClassName(rowData)}
        >
          {dynamicColumns}
        </DataTable>

        <ProjectOrderLineKebabAction
          popOverRef={actionsPopOverRef}
          rowData={selectedRow}
          isMilestoneListLoaded={false}
        ></ProjectOrderLineKebabAction>

        {selectedE2EProjectLineFlexTask !== null && (
          <ProjectCollaboratorAssignmentDialog
            e2EProjectId={props.projectId}
            workBreakdownStructure={props.workBreakdownStructure}
            onClose={onCollaboratorClose}
            show={showCollaboratorAssignmentDialog}
            selectedE2EProjectLineFlexTask={selectedE2EProjectLineFlexTask}
            e2EProjectLineId={selectedE2EProjectLineId}
            projectNumber={selectedProjectNumber}
            isCoHandlerMilestone={selectedIsCoHandlerMilestone}
          />
        )}

        <TimestampElements
          projectTimestampHook={projectTimestampHook}
          onECDAdjustmentsConfirm={handleECDAdjustmentsConfirm}
          onSkipInvoicePrice={() => handleOrderLineNumberDoubleClick(dbClickRowData)}
        />
      </div>
    </div>
  );
};
export default ProjectOrderLineList;
