import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, sortBy } from "lodash";
import moment from "moment";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFlexTaskMilestonesList,
  selectSelectedMilestones,
  updateSelectedMilestones,
  upsertFlexTaskMilestones,
  upsertProjectFlexTasksContributors,
  selectedProjectManagementViewProjects,
  selectRecentMilestoneChanges,
} from "../../../features/projectManagement/projectManagementSlice";
import useProjectTimestamp from "../../../hooks/useProjectTimestamp";
import ProjectService from "../../../services/ProjectService";
import { formatDateWithTimezone } from "../../../shared/date-helper/DateHelper";
import { GridColumnModel } from "../data/project-grid-columns";
import {
  projectMilestoneColumnCollaborator,
  projectMilestoneColumnMilestone,
  projectMilestoneColumnOrderLine,
  projectMilestoneColumnPredecessor,
  projectMilestoneColumnStatus,
  projectMilestoneColumnWbs,
  projectMilestoneCompletionDate,
  projectMilestoneDueDate,
  projectMilestoneStartDate,
} from "../data/project-milestone-grid-columns";
import ProjectCollaboratorAvatar from "../shared/components/project-collaborator-avatar/ProjectCollaboratorAvatar";
import PmrE2EProjectHandler from "../shared/interfaces/pmr-e2e-project-handlers";
import {
  PmrE2EProjectFlexTaskMilestones,
  PmrE2EProjectFlexTaskMilestonesList,
} from "../shared/interfaces/pmr-e2e-project-milestone";
import PmrE2EProjectFlexTaskMilestonesGroup from "../shared/interfaces/pmr-e2e-project-milestone-group";
import PmrE2EProjectLineFlexTaskMilestonesModel from "../shared/interfaces/pmr-e2e-project-orderline-milestone-model";
import { IProjectMilestoneSteps } from "../shared/interfaces/pmr-e2e-project-task-milestone-step";

import clsx from "clsx";
import { Button } from "primereact/button";
import { E2EProjectLineMilestoneModel } from "../../../shared/models/E2EProject.model";
import { ProjectTasksMilestoneSteps } from "../data-mock/project-task-milestone-steps";
import ProjectOrderLineKebabAction from "../shared/components/project-orderline-kebab-actions/ProjectOrderLineKebabActions";
import classes from "./ProjectMilestoneList.module.scss";
import { formatNumber } from "../../../utils/helpers/number.helpers";
import PmrE2EProjectTaskHandler from "../shared/interfaces/pmr-e2e-project-task-handlers";
import ProjectCollaboratorAssignmentDialog from "../shared/components/project-collaborator-assignment-dialog/ProjectCollaboratorAssignmentDialog";
import TimestampElements from "../../project-management/shared/components/timestamp-elements/TimestampElements";
import { ProjectManagementPmrWrapper } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import {
  generateGlobalSearchFilters,
  selectShowHideColumnProjectsTableMilestone,
  updateShowHideColumnByTable,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import PmrDmIcons from "../shared/components/pmr-dm-icons/PmrDmIcons";
import PmrE2EProjectTaskModel, {
  PmrE2EProjectTaskModelList,
} from "../shared/interfaces/pmr-e2e-project-task-model";
import { isGmaTcMilestone } from "../../../utils/constants/flex-task-name.constants";
import usePmrShiftSelect, {
  ComponentWithIndices,
} from "../../../hooks/usePmrShiftSelect";
import SubmitOption from "../shared/enums/submit-option";
import { getProjectLineIsCompletedForMilestone } from "../shared/interfaces/pmr-e2e-project-details-orderline-model";
import MilestoneDateTemplate from "./milestone-date-template/MilestoneDateTemplate";
import * as axios from "axios";
import { ECDAdjustmentsErrorType } from "../shared/enums/ecd-adjustments-error-type";
import useToastr from "../../../hooks/useToastr";
import { CoHandlerErrorStatus } from "../shared/enums/co-handler-error-status";
import {
  coHandlerErrorHeader,
  coHandlerErrorMessage,
} from "../../../utils/constants/projects.constants";
import ProjectMilestoneStatusTemplate from "../shared/components/project-milestone-status-template/ProjectMilestoneStatusTemplate";
import ProjectMilestoneStatus from "../shared/enums/project-milstone-status";
import ProjectStatus from "../shared/enums/project-status";

interface ProjectLineMilestoneTaskProps extends ComponentWithIndices {
  e2eProjectId: string | null;
  workBreakdownStructure: number;
  e2EProjectLineFlexTaskIds: string[];
  flexTaskName: string;
  getTaskList: () => void;
  isProjectCompletionCompleted: boolean;
  hasGMATCTask: boolean | null;
  openAssignCoHandlerDialog?(
    rowData: any,
    isMilestoneLevel: boolean,
    showTimestampError: boolean,
    index?: number
  ): void;
  isAdHoc: boolean;
}

const ProjectMilestoneList = (props: ProjectLineMilestoneTaskProps) => {
  const { showError } = useToastr();
  const projects = useSelector(selectedProjectManagementViewProjects);
  const [loading, setLoading] = useState(false);
  const flexTaskMilestonesList = useSelector(selectFlexTaskMilestonesList);
  const recentMilestoneChanges = useSelector(selectRecentMilestoneChanges);
  const projectMilestoneColumnsSelector = useSelector(
    selectShowHideColumnProjectsTableMilestone
  );
  const milestoneList =
    flexTaskMilestonesList.find(
      (list) =>
        list.projectId === props.e2eProjectId &&
        list.workBreakdownStructure === props.workBreakdownStructure
    )?.milestones || [];
  const [columns, setColumns] = useState<GridColumnModel[]>(
    projectMilestoneColumnsSelector!.fields
  );
  const taskMilestoneList =
    ProjectTasksMilestoneSteps.find(
      (list) => list.taskName === props.flexTaskName
    )?.milestones || [];

  const datatableRef = useRef<any>(null);
  const dispatch = useDispatch();
  const selectedMilestones = useSelector(selectSelectedMilestones);
  const projectTimestampHook = useProjectTimestamp();
  const generatedGlobalFilters = useSelector<
    ProjectManagementPmrWrapper,
    { label: string; value?: any }[]
  >((state) => generateGlobalSearchFilters(state, props.e2eProjectId ?? ""));
  const shiftSelect = usePmrShiftSelect(props.depthIndices, milestoneList);
  useEffect(() => {
    if (shiftSelect.rangeItems.length > 0) {
      dispatch(updateSelectedMilestones(shiftSelect.rangeItems));
    }
  }, [shiftSelect.rangeItems]);

  useEffect(() => {
    setColumns(projectMilestoneColumnsSelector!.fields);
  }, [projectMilestoneColumnsSelector]);

  useEffect(() => {
    if (datatableRef.current) {
      datatableRef.current.restoreColumnWidths();
      datatableRef.current.reset();
    }
  }, [columns]);

  const getSortOrder = (name: string) => {
    const milestone = taskMilestoneList!.find(
      (item: IProjectMilestoneSteps) => item.name === name
    );
    return milestone?.sortOrder;
  };

  const actionsPopOverRef = useRef<any>(null);
  const [dbClickData, setDbClickData] = useState<{
    rowData: any,
    index: number
  } | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedE2EProjectLineFlexTask, setSelectedE2EProjectLineFlexTask] =
    useState<{
      e2EProjectLineFlexTaskId: string;
      collaborators: PmrE2EProjectTaskHandler[];
      isGmaTcTask: boolean;
    }>();

  const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
  const [selectedE2EProjectLineId, setSelectedE2EProjectLineId] = useState("");
  const [selectedProjectNumber, setSelectedProjectNumber] = useState<string>();
  const [selectedIsCoHandlerMilestone, setSelectedIsCoHandlerMilestone] =
    useState(false);

  const sortData = (data: PmrE2EProjectFlexTaskMilestones[]) => {
    const orderByOrderLine = [...data].sort((a, b) => {
      const orderLineA = parseInt(a.orderLineNumber.split(".")[0]);
      const orderLineB = parseInt(b.orderLineNumber.split(".")[0]);
      return orderLineA - orderLineB;
    });

    return orderByOrderLine.sort((a, b) => {
      return a.externalId - b.externalId;
    });
  };

  const getWbs = (projectId: string, milestoneId: string) => {
    const project = projects.find((project) => project.id === projectId);
    if (!project) return "";

    for (const task of project.flexMilestonesGroup || []) {
      const milestone = task.milestones.find(
        (milestone: { milestoneIds: string[] }) =>
          milestone.milestoneIds.includes(milestoneId)
      );
      if (milestone) return milestone.wbs;
    }

    return "";
  };

  const isRecentlyChanged = (
    rowData: PmrE2EProjectFlexTaskMilestonesGroup,
    milestoneIndex: number,
    fieldName: string
  ) => {
    const milestoneId = rowData.id[milestoneIndex];

    return recentMilestoneChanges.some(
      (change) =>
        change.e2EProjectLineMilestoneId === milestoneId &&
        change.fieldChanged === fieldName
    );
  };

  const groupedAndFormatData = (data: PmrE2EProjectFlexTaskMilestones[]) => {
    type GroupedData = { [key: string]: PmrE2EProjectFlexTaskMilestonesGroup };
    const groupedData: GroupedData = {};
    let childWbs: number = 0;

    data.forEach((item) => {
      const key = `${item.milestone}`;
      const wbs = props.isAdHoc
        ? `${props.workBreakdownStructure} - 1`
        : getWbs(props.e2eProjectId ?? "", item.id);

      if (!groupedData[key]) {
        childWbs++;

        groupedData[key] = {
          id: [],
          wbs,
          milestone: item.milestone,
          orderLineNumber: [],
          projectNumber: [],
          orderNumber: [],
          status: [],
          collaborator: [],
          duration: [],
          startDate: [],
          dueDate: [],
          completionDate: [],
          e2EProjectLineId: [],
          isTimestamping: [],
          predecessor: [],
          e2EProjectLineFlexTaskId: [],
          isRevenueRecognition: [],
          isLastMilestone: [],
          predecessorTaskStatus: [],
          successorTaskStatus: [],
          schemeId: [],
          schemeName: [],
          flexProjectName: [],
          e2EProjectLineWorkflowMapping: [],
          e2EProjectLineModelInfoLinkToCM: [],
          flexTaskName: item.flexTaskName,
          paymentOption: [],
          submitOption: [],
          flexProjectId: [],
          hasGmaTcTask: [],
          e2EProjectLineStatus: [],
          isCpq: [],
          flexProjectTaskHoldReason: [],
          totalHours: [],
          myHours: [],
          onHoldReason: [],
          onHoldReasonId: [],
          isAdHoc: [],
          isOrderlineAssociated: [],
          predecessorTaskStatuses: [],
          orderBookedDate: item.orderBookedDate,
        };
      }

      const milestoneContributors: PmrE2EProjectHandler[] = [];
      item.collaborator?.forEach((contributor: PmrE2EProjectHandler) => {
        const collaborator: PmrE2EProjectHandler = {
          assignOrder: contributor.assignOrder,
          displayName: contributor.displayName,
          isActive: contributor.isActive,
          userEmail: contributor.userEmail,
          handlerType: contributor.handlerType,
        };

        milestoneContributors.push(collaborator);
      });

      const predecessors = sortBy(
        item.e2EMilestonePredecessors?.map((milestone) =>
          getWbs(
            props.e2eProjectId!,
            milestone.predecessorE2EProjectLineMilestoneId
          )
        ) || [],
        (wbs) => wbs
      ).join(", ");
      // Deprecated, to be removed later.
      const predecessor = item.predecessorId
        ? getWbs(props.e2eProjectId!, item.predecessorId!)
        : "";

      groupedData[key].collaborator.push(milestoneContributors);

      groupedData[key].id.push(item.id);
      groupedData[key].orderLineNumber.push(item.orderLineNumber);
      groupedData[key].projectNumber.push(item.projectNumber);
      groupedData[key].orderNumber.push(item.orderNumber);
      groupedData[key].duration.push(item.duration || "");
      groupedData[key].startDate.push(item.startDate || "");
      groupedData[key].dueDate.push(item.dueDate || "");
      groupedData[key].completionDate.push(item.completionDate || "");
      groupedData[key].status.push(item.status);
      groupedData[key].e2EProjectLineId.push(item.e2EProjectLineId);
      groupedData[key].isTimestamping?.push(item.isTimestamping || false);
      groupedData[key].predecessor.push(predecessors || predecessor || "");
      groupedData[key].e2EProjectLineFlexTaskId.push(
        item.e2EProjectLineFlexTaskId
      );
      groupedData[key].isRevenueRecognition.push(item.isRevenueRecognition);
      groupedData[key].isLastMilestone.push(item.isLastMilestone);
      groupedData[key].flexProjectId?.push(item.flexProjectId ?? "");
      groupedData[key].flexTaskStatus = item.flexTaskStatus;
      if (item.predecessorTaskStatus)
        groupedData[key].predecessorTaskStatus?.push(
          item.predecessorTaskStatus
        );
      if (item.successorTaskStatus)
        groupedData[key].successorTaskStatus?.push(item.successorTaskStatus);

      groupedData[key].flexProjectName?.push(item.flexProjectName ?? null);
      groupedData[key].e2EProjectLineWorkflowMapping?.push(
        item.e2EProjectLineWorkflowMapping ?? null
      );
      groupedData[key].e2EProjectLineModelInfoLinkToCM?.push(
        item.e2EProjectLineModelInfoLinkToCM ?? null
      );
      groupedData[key].schemeId?.push(item.schemeId ?? null);
      groupedData[key].schemeName?.push(item.schemeName ?? null);
      groupedData[key].paymentOption?.push(item.paymentOption ?? null);
      groupedData[key].submitOption?.push(item.submitOption ?? null);
      groupedData[key].hasGmaTcTask?.push(item.hasGmaTcTask ?? null);
      groupedData[key].e2EProjectLineStatus.push(item.e2EProjectLineStatus);
      if (
        item.submitOption == SubmitOption.AuthorityReviewFlex &&
        item.milestone == "UL: Submit to Authority/Authority Review"
      ) {
        groupedData[key].openAssignCoHandlerDialog =
          props.openAssignCoHandlerDialog;
        groupedData[key].e2EProjectId = props.e2eProjectId;
        groupedData[key].workBreakdownStructure = props.workBreakdownStructure;
        groupedData[key].isAssignCoHandlerMailboxNotRequired =
          item.isAssignCoHandlerMailboxNotRequired;
      }
      groupedData[key].isCpq?.push(item.isCpq ?? null);
      groupedData[key].flexProjectTaskHoldReason?.push(
        item.flexProjectTaskHoldReason ?? null
      );
      groupedData[key].e2EProjectId = item.e2EProjectId;
      groupedData[key].e2EProjectName = item.e2EProjectName;
      groupedData[key].orderLineDescription = item.orderLineNumber;
      groupedData[key].totalHours = item.totalHours;
      groupedData[key].myHours = item.myHours;
      groupedData[key].name = item.name;
      groupedData[key].onHoldReason?.push(item.onHoldReason ?? null);
      groupedData[key].onHoldReasonId?.push(item.onHoldReasonId ?? null);
      groupedData[key].isAdHoc?.push(item.isAdHoc ?? false);
      groupedData[key].isOrderlineAssociated?.push(
        item.isOrderlineAssociated ?? false
      );
      groupedData[key].predecessorTaskStatuses.push(
        item.predecessorTaskStatuses ?? null
      );
      groupedData[key].orderBookedDate = item.orderBookedDate;
    });

    return Object.values(groupedData);
  };

  const formattedMilestoneList = groupedAndFormatData(milestoneList);

  const getListCancelSource = useRef<any>(null);
  const getList = async () => {
    setLoading(true);
    const request: any = {
      e2EProjectLineFlexTaskIds: props.e2EProjectLineFlexTaskIds,
      workBreakdownStructure: props.workBreakdownStructure,
    };

    if (getListCancelSource.current) {
      getListCancelSource.current.cancel();
    }

    getListCancelSource.current = (axios as any).CancelToken.source();

    try {
      const milestoneResult = await ProjectService.getProjectMilestoneTask(
        request,
        getListCancelSource.current
      );

      if (milestoneResult?.data?.length === 0) return;

      let milestoneTaskList =
        milestoneResult.data?.e2EProjectLineMilestones.map(
          (milestone: PmrE2EProjectLineFlexTaskMilestonesModel) => {
            const milestoneTask: PmrE2EProjectFlexTaskMilestones = {
              id: milestone.id,
              milestone: milestone.name,
              sortOrder: milestone.sortOrder,
              orderLineNumber: milestone?.e2EProjectLine?.orderLineDescription,
              projectNumber: milestone?.e2EProjectLine?.flexProjectNumber,
              orderNumber: milestone?.e2EProjectLine?.orderNumber,
              status: milestone?.status,
              collaborator: milestone?.e2EProjectLineMilestoneCollaborators,
              totalHours: formatNumber(milestone?.totalHours),
              myHours: formatNumber(milestone?.myHours),
              duration:
                (
                  Math.round(milestone?.durationInDays * 100) / 100
                ).toString() || "",
              startDate: formatDateWithTimezone(milestone?.startDate)!,
              dueDate: formatDateWithTimezone(milestone?.dueDate)!,
              completionDate:
                formatDateWithTimezone(milestone?.completionDate) || "",
              predecessor: milestone?.predecessor,
              wbs: null,
              e2EProjectLineFlexTaskId: milestone.e2EProjectLineFlexTaskId,
              externalId: getSortOrder(milestone.name) || 0,
              e2EProjectLineId: milestone.e2EProjectLineId,
              isTimestamping: milestone.isTimestamping,
              isRevenueRecognition: milestone.isRevenueRecognition,
              isLastMilestone: milestone.isLastMilestone,
              flexProjectId: (milestone?.e2EProjectLine as any).flexProjectId,
              flexTaskStatus:
                milestone?.e2EProjectLine?.e2EProjectLineFlexTasks[0]
                  ?.flexTaskStatus,
              predecessorTaskStatus:
                milestone?.predecessorTaskStatus?.toString(),
              successorTaskStatus: milestone?.successorTaskStatus?.toString(),
              e2EProjectLineWorkflowMapping:
                milestone?.e2EProjectLineWorkflowMapping,
              e2EProjectLineModelInfoLinkToCM:
                milestone?.e2EProjectLineModelInfoLinkToCM,
              flexProjectName:
                milestone?.e2EProjectLine?.e2EFlexProject?.flexProjectName,
              schemeId: milestone?.e2EProjectLine?.schemeId,
              schemeName: milestone?.schemeName,
              flexTaskName:
                milestone?.e2EProjectLine?.e2EProjectLineFlexTasks[0]
                  ?.flexTaskName ?? milestone.flexTaskName,
              paymentOption: milestone?.paymentOption,
              submitOption: milestone?.submitOption,
              hasGmaTcTask: milestone?.hasGmaTcTask,
              predecessorId: milestone?.predecessorId?.toString(),
              e2EProjectLineStatus: milestone?.e2EProjectLineStatus,
              isAssignCoHandlerMailboxNotRequired:
                milestone?.e2EProjectLine.isAssignCoHandlerMailboxNotRequired,
              isCpq: milestone?.isCpq,
              flexProjectTaskHoldReason: milestone?.flexProjectTaskHoldReason,
              e2EMilestonePredecessors: milestone?.e2EMilestonePredecessors,
              e2EProjectId: milestone?.e2EProjectId,
              name: milestone?.name,
              orderLineDescription:
                milestone?.e2EProjectLine?.orderLineDescription,
              e2EProjectName: milestone.e2EProjectName,
              onHoldReason: milestone.onHoldReason,
              onHoldReasonId: milestone.onHoldReasonId,
              isAdHoc: milestone.isAdHoc,
              isOrderlineAssociated: milestone.isOrderlineAssociated,
              predecessorTaskStatuses: milestone.predecessorTaskStatuses,
              orderBookedDate: milestone.e2EProjectLine.orderBookedDate,
            };
            return milestoneTask;
          }
        );

      const sortedData = sortData(milestoneTaskList);

      dispatch(
        upsertFlexTaskMilestones({
          projectId: props.e2eProjectId,
          workBreakdownStructure: props.workBreakdownStructure,
          milestones: sortedData,
        } as PmrE2EProjectFlexTaskMilestonesList)
      );
    } catch (e) {
      if ((axios as any).isCancel(e)) {
        console.warn("Get Milestone request cancelled");
        return;
      }
    }

    setLoading(false);
  };

  const getOverdueClass = (dueDate: string, status: ProjectMilestoneStatus) => {
    if (!dueDate) return false;
    const formattedDueDate = new Date(dueDate);
    const currentDateOnly = moment().startOf("day").toDate();
    const overDueStatuses = new Set([
      ProjectMilestoneStatus.NotScheduled,
      ProjectMilestoneStatus.AwaitingAssignment,
      ProjectMilestoneStatus.NotStarted,
      ProjectMilestoneStatus.InProgress,
      ProjectMilestoneStatus.OnHold,
    ]);

    return currentDateOnly > formattedDueDate && overDueStatuses.has(status);
  };

  const tableColumnHeader = (col: GridColumnModel) => (
    <div>
      <span title={col.value}>{col.value}</span>
    </div>
  );

  const tableColumnBody = (rowData: any, col: ColumnBodyOptions) => {
    const columnData = get(rowData, col.field);
    return (
      <div>
        {Array.isArray(columnData) &&
          columnData.map((orderline: string, index: number) => {
            return (
              <div
                key={index}
                title={orderline}
                className={clsx(
                  "milestone-tbl-col",
                  "text-container-ellipsis",
                  getRowTimestampingClassName(rowData, index),
                  getOverdueClass(
                    rowData.dueDate[index],
                    rowData.status[index]
                  ) && classes["order-line-overdue"],
                  getProjectLineIsCompletedForMilestone(
                    rowData.e2EProjectLineStatus[index],
                    rowData.status[index]
                  ) && classes["project-line-is-completed"]
                )}
                style={{
                  borderBottom:
                    index + 1 != columnData.length ? "1px solid #ddd" : "",
                  padding: "10px",
                }}
              >
                <span
                  className={clsx(
                    isRecentlyChanged(rowData, index, "FormattedDueDate") &&
                    col.field === "dueDate" &&
                    "recently-changed"
                  )}
                >
                  {orderline}
                </span>
              </div>
            );
          })}
      </div>
    );
  };
  const isOrderlineSelected = (orderLine: E2EProjectLineMilestoneModel) => {
    return selectedMilestones.some(
      (line) => line.id === orderLine.id?.toString()
    );
  };

  const onOrderLineKebabClicked = (rowData: any, index: number, e: any) => {
    const data = {
      id: rowData.id[index],
      status: rowData.status[index],
      e2EProjectLineId: rowData.e2EProjectLineId[index],
      isTimestamping: isTimestamping(rowData, index),
      e2EProjectLineFlexTaskId: rowData.e2EProjectLineFlexTaskId[index],
      isRevenueRecognition: rowData.isRevenueRecognition[index],
      isLastMilestone: rowData.isLastMilestone[index],
      totalHours: rowData.totalHours && rowData.totalHours[index],
      orderLineDescription: rowData.orderLineNumber[index],
      flexProjectNumber: rowData.projectNumber[index],
      e2EProjectId: props.e2eProjectId,
      flexProjectId: rowData.flexProjectId[index],
      flexTaskStatus: rowData.flexTaskStatus,
      predecessorTaskStatus: rowData.predecessorTaskStatus[index],
      successorTaskStatus: rowData.successorTaskStatus[index],
      isProjectCompletionCompleted: props.isProjectCompletionCompleted,
      openAssignCoHandlerDialog: props.openAssignCoHandlerDialog,
      isAdHoc: rowData.isAdHoc[index],
    };
    setSelectedRow(data);
    if (actionsPopOverRef.current?.openPopUp)
      actionsPopOverRef?.current?.openPopUp(e);
  };

  const getSelectedMilestoneState = (
    rowData: PmrE2EProjectFlexTaskMilestonesGroup,
    index: number
  ) => {
    return {
      id: rowData.id[index],
      status: rowData.status[index],
      e2EProjectLineId: rowData.e2EProjectLineId[index],
      isTimestamping: isTimestamping(rowData, index),
      e2EProjectLineFlexTaskId: rowData.e2EProjectLineFlexTaskId[index],
      isRevenueRecognition: rowData.isRevenueRecognition[index],
      isLastMilestone: rowData.isLastMilestone[index],
      isAdHoc: rowData.isAdHoc![index],
    };
  };

  const nameColumnBodyTemplate = (rowData: any, col: ColumnBodyOptions) => {
    const orderLineData = get(rowData, col.field);
    const startIndex = orderLineData.length * col.rowIndex;

    return (
      <div>
        {orderLineData.map((milestone: any, index: number) => {
          const isCheckboxDisabled = [
            ProjectStatus.Cancelled,
            ProjectStatus.Completed,
          ].includes(rowData.status[index]);
          return (
            <div
              key={index}
              className={clsx(
                "milestone-tbl-col",
                getRowTimestampingClassName(rowData, index),
                classes["orderline-cell-container"]
              )}
              style={{
                borderBottom:
                  index + 1 !== orderLineData.length ? "1px solid #ddd" : "",
                padding: "10px",
              }}
            >
              {!getProjectLineIsCompletedForMilestone(
                rowData.e2EProjectLineStatus[index],
                rowData.status[index]
              ) && (
                  <Checkbox
                    disabled={isCheckboxDisabled}
                    checked={
                      !isCheckboxDisabled &&
                      isOrderlineSelected({
                        id: rowData.id[index],
                      })
                    }
                    onChange={(event: CheckboxChangeParams) => {
                      shiftSelect.register(
                        [...props.depthIndices, startIndex + index],
                        event
                      );
                      dispatch(
                        updateSelectedMilestones({
                          isChecked: event.checked,
                          milestone: getSelectedMilestoneState(rowData, index),
                        })
                      );
                    }}
                    className={`${classes["task-checkbox"]}`}
                  />
                )}
              {getProjectLineIsCompletedForMilestone(
                rowData.e2EProjectLineStatus[index],
                rowData.status[index]
              ) && (
                  <span className={classes["check-icon"]}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                )}

              <div
                className={clsx(
                  "milestone-tbl-col",
                  classes["milestone-number"],
                  getOverdueClass(
                    rowData.dueDate[index],
                    rowData.status[index]
                  ) && classes["order-line-overdue"],
                  getProjectLineIsCompletedForMilestone(
                    rowData.e2EProjectLineStatus[index],
                    rowData.status[index]
                  ) && classes["project-line-is-completed"]
                )}
                title={
                  getOverdueClass(
                    rowData.dueDate[index],
                    rowData.status[index]
                  ) &&
                    !getProjectLineIsCompletedForMilestone(
                      rowData.e2EProjectLineStatus[index],
                      rowData.status[index]
                    )
                    ? "This Task/Milestone is overdue. Please review and take necessary action"
                    : milestone
                }
              >
                <div
                  onDoubleClick={() =>
                    handleMilestoneNumberDoubleClick(rowData, index)
                  }
                >
                  {milestone}
                </div>
              </div>
              <div className={`${classes["kebab-icon"]}`}>
                <PmrDmIcons
                  rowData={Object?.keys(rowData || [])
                    .filter((hasKey) => hasKey)
                    .reduce((prev, current) => {
                      let returnValue = rowData[current];
                      if (returnValue?.constructor === Array) {
                        returnValue = rowData[current][index];
                      }
                      return {
                        ...prev,
                        [current]: returnValue,
                      };
                    }, {})}
                />
                <Button
                  type="button"
                  icon="pi pi-ellipsis-v"
                  onClick={(e) => onOrderLineKebabClicked(rowData, index, e)}
                  className={`p-button-text p-button-secondary ${classes["action-button"]} milestone-row-index-${col.rowIndex}-${index} milestone-status-${rowData.status[index]} `}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleMilestoneCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectFlexTaskMilestonesGroup
  ) => {
    rowData.id.forEach((id, index) => {
      dispatch(
        updateSelectedMilestones({
          milestone: getSelectedMilestoneState(rowData, index),
          isChecked: event.checked,
        })
      );
    });
  };

  const isMilestoneChecked = (
    rowData: PmrE2EProjectFlexTaskMilestonesGroup
  ) => {
    const data = rowData.id.filter(
      (_, index) =>
        ![ProjectStatus.Cancelled, ProjectStatus.Completed].includes(
          rowData.status[index]
        )
    );

    if (data.length <= 0) return false;
    return data.every((id, index) => isOrderlineSelected({ id }));
  };

  const milestoneColumnBodyTemplate = (
    rowData: PmrE2EProjectFlexTaskMilestonesGroup,
    col: ColumnBodyOptions
  ) => {
    return (
      <div className={`${classes["milestone-cell-container"]}`}>
        <div className={`${classes["left"]}`} title={get(rowData, col.field)}>
          <Checkbox
            checked={isMilestoneChecked(rowData)}
            onChange={(event: CheckboxChangeParams) => {
              handleMilestoneCheck(event, rowData);
            }}
            className={`${classes["task-checkbox"]}`}
          />
          {get(rowData, col.field)}
        </div>
      </div>
    );
  };

  const predecessorColumnBody = (rowData: any) => {
    let render = rowData.predecessor.map(
      (predecessor: string, index: number) => {
        if (predecessor !== "") {
          return (
            <div
              className={clsx(
                "milestone-tbl-col",
                getProjectLineIsCompletedForMilestone(
                  rowData.e2EProjectLineStatus[index],
                  rowData.status[index]
                ) && classes["project-line-is-completed"],
                `${classes["wbs-predecessor-cell-container"]}`
              )}
              key={index}
              title={predecessor}
              style={{
                borderBottom:
                  index + 1 != rowData.predecessor.length
                    ? "1px solid #ddd"
                    : "",
              }}
            >
              <div className={classes["predecessor-cell"]}>{predecessor}</div>
            </div>
          );
        } else {
          return (
            <div
              className={`milestone-tbl-col ${classes["wbs-predecessor-cell-container"]}`}
              key={index}
              style={{
                borderBottom:
                  index + 1 != rowData.predecessor.length
                    ? "1px solid #ddd"
                    : "",
              }}
            >
              &nbsp;
            </div>
          );
        }
      }
    );

    return <div>{render}</div>;
  };

  const wbsColumnBody = (rowData: any, col: ColumnBodyOptions) => {
    return (
      <div
        className={`${classes["wbs-cell-container"]}`}
        title={get(rowData, col.field)}
        style={{ borderRight: "1px solid #ddd" }}
      >
        <div>{get(rowData, col.field)}</div>
      </div>
    );
  };

  const projectCompletionDateTemplate = (rowData: any) => {
    let render = rowData.completionDate.map(
      (completionDate: string, index: number) => {
        if (completionDate !== "") {
          return (
            <div
              key={index}
              title={completionDate}
              className={clsx("milestone-tbl-col")}
              style={{
                borderBottom:
                  index + 1 != rowData.completionDate.length
                    ? "1px solid #ddd"
                    : "",
                padding: "10px",
              }}
            >
              <div
                className={clsx(
                  getProjectLineIsCompletedForMilestone(
                    rowData.e2EProjectLineStatus[index],
                    rowData.status[index]
                  ) && classes["project-line-is-completed"]
                )}
              >
                {completionDate}
              </div>
            </div>
          );
        } else {
          return (
            <div
              className={clsx(
                "milestone-tbl-col",
                getRowTimestampingClassName(rowData, index)
              )}
              key={index}
              style={{
                borderBottom:
                  index + 1 != rowData.completionDate.length
                    ? "1px solid #ddd"
                    : "",
                padding: "10px",
              }}
            >
              &nbsp;
            </div>
          );
        }
      }
    );

    return <div>{render}</div>;
  };

  const renderStartDateTemplate = (rowData: any) => {
    return (
      <div>
        <MilestoneDateTemplate
          fieldName="startDate"
          isStartDate={true}
          dateLabel="Start Date"
          rowData={rowData}
          dateClassName={(index: number) =>
            clsx(
              "milestone-tbl-col-date",
              getOverdueClass(rowData.dueDate[index], rowData.status[index]) &&
              classes["order-line-overdue"],
              getProjectLineIsCompletedForMilestone(
                rowData.e2EProjectLineStatus[index],
                rowData.status[index]
              ) && classes["project-line-is-completed"]
            )
          }
        />
      </div>
    );
  };

  const renderDueDateTemplate = (rowData: any) => {
    return (
      <div>
        <MilestoneDateTemplate
          isStartDate={false}
          fieldName="dueDate"
          dateLabel="Due Date"
          rowData={rowData}
          dateClassName={(index: number) =>
            clsx(
              "milestone-tbl-col-date",
              getOverdueClass(rowData.dueDate[index], rowData.status[index]) &&
              classes["order-line-overdue"],
              getProjectLineIsCompletedForMilestone(
                rowData.e2EProjectLineStatus[index],
                rowData.status[index]
              ) && classes["project-line-is-completed"]
            )
          }
        />
      </div>
    );
  };

  const handleResetList = () => {
    getList();
  };

  const getRowTimestampingClassName = (rowData: any, index: number) => {
    return clsx(isTimestamping(rowData, index) && classes["is-timestamping"]);
  };

  const isTimestamping = (rowData: any, index: number) => {
    return rowData.isTimestamping && rowData.isTimestamping[index];
  };

  const statusColumnBodyTemplate = (rowData: any, col: ColumnBodyOptions) => {
    const columnData = get(rowData, col.field);
    const defaultTooltip = "Current status of this order line's Milestone.";

    return (
      <div>
        {Array.isArray(columnData) &&
          columnData.map((status: ProjectMilestoneStatus, index: number) => {
            const taskStatusReason =
              rowData.status[index] == ProjectMilestoneStatus.OnHold
                ? rowData.onHoldReason[index]
                : null;
            const tooltip = taskStatusReason || defaultTooltip;

            return (
              <div
                key={index}
                className={clsx(
                  "milestone-tbl-col",
                  "text-container-ellipsis",
                  getRowTimestampingClassName(rowData, index),
                  getOverdueClass(rowData.dueDate, rowData.status) &&
                  classes["order-line-overdue"],
                  getProjectLineIsCompletedForMilestone(
                    rowData.e2EProjectLineStatus[index],
                    rowData.status[index]
                  ) && classes["project-line-is-completed"]
                )}
                style={{
                  borderBottom:
                    index + 1 != columnData.length ? "1px solid #ddd" : "",
                  padding: "10px",
                }}
              >
                <ProjectMilestoneStatusTemplate
                  containerStyle={{ cursor: "pointer", width: "100%" }}
                  milestoneData={rowData}
                  status={rowData.status[index]}
                  index={index}
                  isLoading={isTimestamping(rowData, index)}
                  tooltip={tooltip}
                  e2EProjectId={props.e2eProjectId || ""}
                  e2EProjectLineMilestoneId={rowData.id[index]}
                  totalHours={Number(rowData.totalHours)}
                  orderLineDescription={rowData.orderLineNumber[index]}
                  milestoneName={rowData.name}
                  resetList={handleResetList}
                  isAdhoc={props.isAdHoc}
                ></ProjectMilestoneStatusTemplate>
              </div>
            );
          })}
      </div>
    );
  };

  const [
    showCollaboratorAssignmentDialog,
    setShowCollaboratorAssignmentDialog,
  ] = useState<boolean>(false);

  const onCollaboratorClick = (
    e2EProjectLineFlexTaskId: string,
    collaborators: PmrE2EProjectTaskHandler[],
    milestoneId: string,
    isGmaTcTask: boolean,
    e2EProjectLineId: string,
    projectNumber: string
  ) => {
    const currentMileStone: any = milestoneList.find(
      (milestone) => milestone?.id == milestoneId
    );

    if (
      [ProjectStatus.Completed, ProjectStatus.Cancelled].includes(
        currentMileStone.status
      )
    )
      return;

    if (
      currentMileStone.submitOption == SubmitOption.AuthorityReviewFlex &&
      currentMileStone.milestone ==
      "UL: Submit to Authority/Authority Review" &&
      !currentMileStone.isAssignCoHandlerMailboxNotRequired
    ) {
      setSelectedIsCoHandlerMilestone(false);
      return;
    }

    if (
      currentMileStone.submitOption == SubmitOption.AuthorityReviewFlex &&
      currentMileStone.milestone ==
      "UL: Submit to Authority/Authority Review" &&
      currentMileStone.isAssignCoHandlerMailboxNotRequired
    ) {
      setSelectedIsCoHandlerMilestone(true);
    }

    setSelectedE2EProjectLineFlexTask((current) => ({
      ...current,
      e2EProjectLineFlexTaskId: e2EProjectLineFlexTaskId,
      collaborators: collaborators,
      isGmaTcTask: isGmaTcTask,
    }));
    setShowCollaboratorAssignmentDialog(true);
    setSelectedMilestoneId(milestoneId);
    setSelectedE2EProjectLineId(e2EProjectLineId);
    setSelectedProjectNumber(projectNumber);
  };

  const onCollaboratorClose = () => {
    setShowCollaboratorAssignmentDialog(false);
    setSelectedMilestoneId("");
  };

  const collaboratorColumnBodyTemplate = (rowData: any) => {
    const gmaTcTask = "GMA TC_Processing (non-UL CO)";

    const isGmaTcTask = props.flexTaskName
      .toLowerCase()
      .includes(gmaTcTask.toLowerCase());

    return (
      <>
        <div>
          {rowData?.collaborator?.map((collaborator: any, index: number) => {
            return (
              <div
                className={clsx(
                  "milestone-tbl-col",
                  classes["collaborator-milestone-container"],
                  getRowTimestampingClassName(rowData, index)
                )}
              >
                <ProjectCollaboratorAvatar
                  onDoubleClick={() =>
                    onCollaboratorClick(
                      rowData.e2EProjectLineFlexTaskId[index],
                      collaborator,
                      rowData.id[index],
                      isGmaTcTask,
                      rowData.e2EProjectLineId[index],
                      rowData.projectNumber[index]
                    )
                  }
                  collaborator={collaborator}
                  submitOption={rowData.submitOption}
                  isAdHoc={rowData.isAdHoc}
                ></ProjectCollaboratorAvatar>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const processColumnBodyTemplates = (col: GridColumnModel) => {
    if (col.id === projectMilestoneColumnWbs) return wbsColumnBody.bind(col);
    if (col.id === projectMilestoneColumnOrderLine)
      return nameColumnBodyTemplate.bind(col);
    if (col.id === projectMilestoneColumnStatus)
      return statusColumnBodyTemplate.bind(col);
    if (col.id === projectMilestoneColumnCollaborator)
      return collaboratorColumnBodyTemplate.bind(col);
    if (col.id === projectMilestoneColumnMilestone)
      return milestoneColumnBodyTemplate.bind(col);
    if (col.id === projectMilestoneColumnPredecessor)
      return predecessorColumnBody.bind(col);
    if (col.id === projectMilestoneCompletionDate)
      return projectCompletionDateTemplate.bind(col);
    if (col.id === projectMilestoneStartDate)
      return renderStartDateTemplate.bind(col);
    if (col.id === projectMilestoneDueDate)
      return renderDueDateTemplate.bind(col);

    return tableColumnBody.bind(col);
  };

  const dynamicColumns = columns.map((col: GridColumnModel) => {
    const isWbs = col.id === "wbs";
    const isWbsEmpty = col.id === "wbsEmpty";
    const isMilestone = col.id === projectMilestoneColumnMilestone;
    const isOrderLine = col.id === projectMilestoneColumnOrderLine;
    const isPredecessor = col.id === "predecessor";
    const maxWidth = (() => {
      if (isWbs) {
        return 115;
      }
      if (isWbsEmpty) {
        return 50;
      }
      if (isMilestone) {
        return 350;
      }
      if (isOrderLine) {
        return 100;
      }
      if (isPredecessor) {
        return 70;
      }
      if (isPredecessor) {
        return 100;
      }
      return undefined;
    })();

    return (
      <Column
        field={col.id}
        header={tableColumnHeader(col)}
        hidden={!col.isShown}
        colSpan={col.colspan}
        style={{
          flexGrow: col.isFlexWidth ? 1 : undefined,
          flexBasis: col.isFlexWidth ? col.width : undefined,
          width: col.width,
          minWidth: col.widthNum,
          maxWidth: maxWidth,
          borderRight: isWbs ? "none" : "1px solid #ddd",
          alignContent: "flex-start",
        }}
        body={processColumnBodyTemplates(col)}
        sortable={col.orderBy !== ""}
        reorderable={!col.isColumnNotReordable && !col.isFrozen}
        frozen={col.isFrozen || col.frozenPermanent}
      />
    );
  });

  const onColReorder = (e: any) => {
    if (e.dropIndex < 4) {
      datatableRef.current.reset();
    } else {
      let prevNumber = 0;
      let pwqColumns = columns.map((col) => {
        const orderNo = e.columns.findIndex(
          (fi: any) => fi.props.field === col.id
        );

        return {
          ...col,
          orderNo,
        };
      });

      pwqColumns.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      pwqColumns = pwqColumns
        .map((col) => {
          if (col.orderNoShowHide! > 0) prevNumber++;

          const orderNoShowHide = col.orderNoShowHide! > 0 ? prevNumber : 0;

          return {
            ...col,
            orderNoShowHide,
          };
        })
        .sort((col) => col.orderNoShowHide);

      dispatch(
        updateShowHideColumnByTable({
          key: projectMilestoneColumnsSelector?.key,
          newColumns: pwqColumns,
          subHeader: "Milestone",
        })
      );
    }
  };

  const onColResize = (e: any) => {
    let newColumns = columns.map((col) => {
      if (e.column.props.field === col.id) {
        return {
          ...col,
          width: e.element.offsetWidth,
        };
      } else {
        return col;
      }
    });
    dispatch(
      updateShowHideColumnByTable({
        key: projectMilestoneColumnsSelector?.key,
        newColumns: newColumns,
        subHeader: "Milestone",
      })
    );
  };

  useEffect(() => {
    getList();
  }, []);

  const handleMilestoneNumberDoubleClick = async (
    rowData: any,
    index: number
  ) => {
    setDbClickData({
      rowData,
      index
    })
    const milestone = {
      status: rowData.status[index],
      isTimestamping: rowData.isTimestamping[index],
    };

    if (!projectTimestampHook.isMilestoneTimestampable(milestone)) return;

    const milestoneIds = [rowData.id[index]];

    const result = await projectTimestampHook.checkProjectLinesECDAdjustments({
      milestoneIds,
      includeMilestonePredecessorsForTimestamping: false,
      isMilestoneLoaded: true,
    });

    if (result?.isSuccess && result?.data?.ecdAdjustments.length === 0) {
      await projectTimestampHook.timestampMilestones({
        milestoneIds,
        adjustECDProjectLineIds: [],
        isFromECDAdjustmentPrompt: false,
        includeMilestonePredecessorsForTimestamping: false,
        isTimestampedThroughTasks: false,
      });

      getList();
      handleUpdateFlexTaskLevelContributors(true);
    } else {
      if (result?.data.errorType == ECDAdjustmentsErrorType.CoHandlerError) {
        if (result?.data.coHandlerErrorModel.length == 1) {
          const coHandlerError = result.data.coHandlerErrorModel[0];

          if (
            coHandlerError.coHandlerErrorStatus ==
            CoHandlerErrorStatus.Unassigned
          ) {
            if (props.openAssignCoHandlerDialog)
              props.openAssignCoHandlerDialog(rowData, true, true, index);
          } else {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                coHandlerError.orderLineNumber
              )
            );
          }
        } else {
          result.data.coHandlerErrorModel.forEach((item) => {
            showError(
              coHandlerErrorHeader,
              coHandlerErrorMessage.replace(
                "@orderNumber",
                item.orderLineNumber
              )
            );
          });
        }
      }
    }
  };

  const handleECDAdjustmentsConfirm = async (
    adjustECDProjectLineIds: string[]
  ) => {
    projectTimestampHook.hideECDAdjustmentsDialog();
    await projectTimestampHook.timestampMilestones({
      milestoneIds: projectTimestampHook.currentMilestoneIds,
      adjustECDProjectLineIds,
      isFromECDAdjustmentPrompt: true,
      includeMilestonePredecessorsForTimestamping: false,
      isTimestampedThroughTasks: false,
    });

    getList();
    handleUpdateFlexTaskLevelContributors(true);
  };

  const handleUpdateFlexTaskLevelContributors = async (
    isMilestoneListLoaded: boolean
  ) => {
    const response = await ProjectService.getProjectTasksByProject({
      projectId: props.e2eProjectId,
      isMilestoneListLoaded: isMilestoneListLoaded,
    });

    if (response.isSuccess) {
      let records = response?.data?.e2EProjectLineFlexTasks?.map(
        (task: PmrE2EProjectTaskModel, index: number) => {
          task.workBreakdownStructure = index + 1;
          task.isChecked = false;
          return task;
        }
      );

      dispatch(
        upsertProjectFlexTasksContributors({
          e2eProjectId: props.e2eProjectId,
          tasks: records,
        } as PmrE2EProjectTaskModelList)
      );
    }
  };

  return (
    <div className={`${classes["table-container"]}`}>
      <div className={`${classes["project-milestone-list-grid-container"]}`}>
        <TimestampElements
          projectTimestampHook={projectTimestampHook}
          onECDAdjustmentsConfirm={handleECDAdjustmentsConfirm}
          onSkipInvoicePrice={() => {
            if (dbClickData != null) handleMilestoneNumberDoubleClick(dbClickData.rowData, dbClickData.index)
          }}
        />

        <DataTable
          ref={datatableRef}
          dataKey="id"
          emptyMessage="--- No results found. Please try different search criteria or check your spelling. ---"
          value={formattedMilestoneList}
          loading={loading}
          resizableColumns
          size="small"
          scrollDirection="both"
          columnResizeMode="expand"
          removableSort
          rowGroupMode="rowspan"
          sortMode="single"
          sortOrder={1}
          reorderableColumns
          onColReorder={onColReorder}
          onColumnResizeEnd={onColResize}
          showGridlines
        >
          {dynamicColumns}
        </DataTable>

        <ProjectOrderLineKebabAction
          popOverRef={actionsPopOverRef}
          rowData={selectedRow}
          isMilestoneListLoaded={true}
        ></ProjectOrderLineKebabAction>

        {selectedE2EProjectLineFlexTask != null && (
          <ProjectCollaboratorAssignmentDialog
            e2EProjectId={props.e2eProjectId!}
            workBreakdownStructure={props.workBreakdownStructure}
            onClose={onCollaboratorClose}
            show={showCollaboratorAssignmentDialog}
            selectedE2EProjectLineFlexTask={selectedE2EProjectLineFlexTask}
            e2EProjectLineMilestoneId={selectedMilestoneId}
            e2EProjectLineId={selectedE2EProjectLineId}
            projectNumber={selectedProjectNumber}
            isCoHandlerMilestone={selectedIsCoHandlerMilestone}
          />
        )}
      </div>
    </div>
  );
};
export default ProjectMilestoneList;
