import {
  isGmaTcMilestone,
  isGmaTcTask,
} from "../../../../utils/constants/flex-task-name.constants";
import FlexProjectStatus from "../enums/flex-project-status";
import ProjectMilestoneStatus from "../enums/project-milstone-status";
import ProjectOrderlineFlexWritebackStatus from "../enums/project-orderline-flex-writeback-status";
import ProjectOrderlineStatus from "../enums/project-orderline-status";
import ProjectTaskStatus from "../enums/project-task-status";
import PmrE2EProjectOrderlineModel from "./pmr-e2e-project-orderline-model";

export interface E2EProjectLineModelInfo {
  id: string;
  isBaseModel: boolean;
  modelName: string;
  productType: string;
}
export interface E2EProjectLineTechnology {
  id: string;
  technologyId: string;
  technologyName: string;
}
export default interface PmrE2EProjectDetailsOrderlineModel {
  isChecked?: boolean;
  id: string;
  e2EProjectId?: string;
  orderLineStatus: ProjectOrderlineStatus;
  orderLineNumber?: string;
  projectNumber?: string;
  orderNumber?: string;
  flexTemplate?: string;
  gmaWBTemplate?: string;
  orderLineECD?: string | null;
  orderLineCRCD?: string | null;
  cemOrderOwner?: string;
  price?: string;
  currency?: string;
  flexHandler?: string;
  flexHandlerEmail?: string;
  flexProjectCreatedOn: string;
  flexProjectBookedDate: Date;
  gmaWBHandler?: string;
  gmaWBHandlerEmail?: string;
  e2EProjectLineModelInfos?: E2EProjectLineModelInfo[];
  modelNameCsv?: string;
  productTypesCsv?: string;
  technologies?: E2EProjectLineTechnology[];
  technologyCsv?: string;
  gmaWBProjectCreator?: string;
  flexProjectCreatorName?: string;
  ulRemarks?: string;
  customerRemarks?: string;
  fulFillmentStatusDetail?: string;
  fulFillmentStatusDetailId?: string;
  flexWritebackStatusId: ProjectOrderlineFlexWritebackStatus;
  flexProjectId?: string;
  e2EFlexProjectId?: string;
  isAllOrderlineSavedInGmaWb: boolean;
  isMismatchECD: boolean;
  previousECD?: string | null;
  pointOfSale: string | null;
  isAllTasksCompleted: boolean;
  e2EFlexProjectStatus: FlexProjectStatus;
  invoiceCurrencyCode: string | null;
  invoiceCurrencyId: string | null;
  invoicePriceAmount: number | null;
  flexProjectECD: Date | null;
}

export interface PmrE2EProjectDetailsOrderlineList {
  projectId: string;
  orderlineDetailList: PmrE2EProjectDetailsOrderlineModel[];
}
export interface PmrE2EProjectLineUpdateStatusResult {
  id: string;
  projectNumber: string;
  orderLineNumber: string;
  fulFillmentStatusDetailId: string;
  fulFillmentStatusDetail: string;
  flexWritebackStatusId: ProjectOrderlineFlexWritebackStatus;
  orderLineStatus: ProjectOrderlineStatus;
  e2EFlexProjectStatus: FlexProjectStatus;
}
export interface PmrE2EProjectLineUpdateStatusResultModel {
  triggeredBy: string;
  hasFlexProjectStatusUpdate: boolean;
  isSuccessFlexProjectStatusUpdate: boolean;
  data: PmrE2EProjectLineUpdateStatusResult[];
}

export const isRowUpdating = (rowData: PmrE2EProjectDetailsOrderlineModel) => {
  return (
    rowData.flexWritebackStatusId ==
      ProjectOrderlineFlexWritebackStatus.InProgress ||
    rowData.flexWritebackStatusId == ProjectOrderlineFlexWritebackStatus.Failed
  );
};

export const isRowCompleted = (rowData: PmrE2EProjectDetailsOrderlineModel) => {
  return rowData.orderLineStatus == ProjectOrderlineStatus.Completed;
};

export const getDisplay = (status?: ProjectOrderlineStatus) => {
  switch (status) {
    case ProjectOrderlineStatus.InProgress:
      return "In Progress";
    case ProjectOrderlineStatus.OnHold:
      return "On Hold";
    case ProjectOrderlineStatus.Completed:
      return "Completed";
    case ProjectOrderlineStatus.Cancelled:
      return "Cancelled";
    default:
      return "Not defined";
  }
};

export const IsTaskCompleted = (status: ProjectTaskStatus) => {
  return [ProjectTaskStatus.Completed, ProjectTaskStatus.Cancelled].includes(
    status
  );
};

export const IsMilestoneCompleted = (status: ProjectMilestoneStatus) => {
  return [
    ProjectMilestoneStatus.Completed,
    ProjectMilestoneStatus.Cancelled,
  ].includes(status);
};

export const getProjectLineIsCompletedForTask = (
  orderlineStatus: ProjectOrderlineStatus,
  task: PmrE2EProjectOrderlineModel
) => {
  return (
    IsTaskCompleted(task.status) &&
    orderlineStatus == ProjectOrderlineStatus.Completed
  );
};

export const getProjectLineIsCompletedForMilestone = (
  orderlineStatus: ProjectOrderlineStatus,
  milestoneStatus: ProjectMilestoneStatus
) => {
  return (
    IsMilestoneCompleted(milestoneStatus) &&
    orderlineStatus == ProjectOrderlineStatus.Completed
  );
};

export const getProjectLineIsCompletedV2 = (
  orderlineStatus: ProjectOrderlineStatus
) => {
  return orderlineStatus == ProjectOrderlineStatus.Completed;
};

export const isJapan = (rowData: PmrE2EProjectDetailsOrderlineModel) => {
  return rowData.pointOfSale
    ? rowData.pointOfSale.toLowerCase() == "japan"
    : false;
};
export const isCompletedStatusEnabled = (
  rowData: PmrE2EProjectDetailsOrderlineModel
) => {
  return isJapan(rowData) && rowData.isAllTasksCompleted;
};
