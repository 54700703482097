import { useSelector } from "react-redux";
import { selectShowFlexTasks } from "../../../../../features/projectManagementPmr/projectManagementPmrSlice";
import useProjectTimestamp from "../../../../../hooks/useProjectTimestamp";
import InvoicePriceModalBulk from "../../../../project-management-pmr/project-details-order-line-list/invoice-price-modal-bulk/InvoicePriceModalBulk";
import ECDAdjustmentsConfirmationDialog from "../../../../project-management-pmr/shared/components/ecd-adjustments-confirmation-dialog/ECDAdjustmentsConfirmationDialog";
import MassTimestampConfirmationDialog from "../../../../project-management-pmr/shared/components/mass-timestamp-confirmation-dialog/MassTimestampConfirmationDialog";
import TimeEntryModalSourceType from "../../../../project-management-pmr/shared/enums/time-entry-modal-source-type";
import TimeEntryModal from "../../../../project-management-pmr/time-entry-modal/TimeEntryModal";
import InvoicePricePrompt from "./InvoicePricePrompt";
import { useState } from "react";

type Props = {
  projectTimestampHook: ReturnType<typeof useProjectTimestamp>;
  onECDAdjustmentsConfirm: (adjustECDProjectLineIds: string[]) => void;
  onMassTimestampConfirm?: (() => void) | null;
  onSkipInvoicePrice?: () => void;
};

const TimestampElements = ({
  projectTimestampHook,
  onECDAdjustmentsConfirm,
  onMassTimestampConfirm,
  onSkipInvoicePrice
}: Props) => {
  const isFlexTaskOn = useSelector(selectShowFlexTasks);
  // const [invoicePriceModalBulkVisible, setInvoicePriceModalBulkVisible] = useState(false);
  const handleOnMassTimestampConfirm = () => {
    if (onMassTimestampConfirm) {
      onMassTimestampConfirm();
      projectTimestampHook.hideTimestampConfirmationDialog();
    }
  };

  return (
    <>
      <ECDAdjustmentsConfirmationDialog
        onCancel={projectTimestampHook.onCancelECDAdjustmentsPrompt}
        onConfirm={onECDAdjustmentsConfirm}
        e2eProjectLineECDAdjustments={
          projectTimestampHook.e2eProjectLineECDAdjustments
        }
        visible={projectTimestampHook.ecdAdjustmentsDialogVisible}
        e2eProjectLineIds={projectTimestampHook.currentProjectLineIds}
      />

      <MassTimestampConfirmationDialog
        onCancel={projectTimestampHook.onCancelMassTimestampConfirmation}
        onConfirm={handleOnMassTimestampConfirm}
        e2eMilestoneList={projectTimestampHook.milestoneList}
        e2eFlexTaskList={projectTimestampHook.flexTaskList}
        visible={projectTimestampHook.massTimestampConfirmationVisible}
        e2eProjectLineIds={projectTimestampHook.currentProjectLineIds}
        isFlexTaskOn={isFlexTaskOn.isOn}
      />

      {projectTimestampHook.timeEntryModalVisible && (
        <TimeEntryModal
          closeTimeEntryModal={() =>
            projectTimestampHook.setTimeEntryModalVisible(false)
          }
          visible={projectTimestampHook.timeEntryModalVisible}
          e2eProjectLineFlexTaskIds={
            projectTimestampHook.taskIdsWithInvalidTimeEntry
          }
          timeEntryModalSourceType={TimeEntryModalSourceType.TimeStamp}
        />
      )}

      {/* {projectTimestampHook.invoicePriceModalVisible && <InvoicePricePrompt
        data={projectTimestampHook.e2EProjectLineWithInvalidInvoicePrice}
        visible={projectTimestampHook.invoicePriceModalVisible}
        onHide={() => projectTimestampHook.setInvoicePriceModalVisible(false)}
        onReject={onSkipInvoicePrice}
        onConfirm={() => {
          setInvoicePriceModalBulkVisible(true);
          projectTimestampHook.setInvoicePriceModalVisible(false);
        }}
      />} */}

      {projectTimestampHook.invoicePriceModalVisible && (
        <InvoicePriceModalBulk
          data={projectTimestampHook.e2EProjectLineWithInvalidInvoicePrice}
          visible={projectTimestampHook.invoicePriceModalVisible}
          onHide={() => projectTimestampHook.setInvoicePriceModalVisible(false)}
        />
      )}
    </>
  );
};

export default TimestampElements;
